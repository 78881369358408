<template>
    <div class="location-bg mt-40" id="location">
        <div class="xl:container mx-auto py-16 px-8">
            <div class="location">
                <div class="mb-16">
                    <h1 v-if="this.routeName === 'japan2023'" class="text-4xl sm:text-5xl font-bold text-white text-center">MORE PICKUP</h1>
                    <p v-if="this.routeName === 'japan2023'" class="text-4xl sm:text-5xl font-bold text-white text-center mb-8">LOCATIONS</p>
                    <h2 v-if="this.routeName === 'japan2023'" class=" mb-8 text-4xl sm:text-5xl text-white text-center ">Drop Off / Pickup</h2>
                    <h1 v-if="this.routeName !== 'japan2023'" class="text-4xl sm:text-5xl font-bold text-white text-center">{{ $t('travel.title') }}</h1>
                    <p v-if="this.routeName !== 'japan2023'" class="text-4xl sm:text-5xl font-bold text-white text-center mb-8">{{ $t('travel.subtitle') }}</p>
                    <hr v-if="this.routeName !== 'japan2023'" class=" w-12 main-bg h-1 mb-4 mx-auto xl:mx-0"/>
                    <h2 v-if="this.routeName !== 'japan2023'" class=" mb-8 text-4xl sm:text-5xl text-white text-center xl:text-left">{{ $t('travel.locs') }}</h2>
                </div>

                <Carousel class="loc-slider" :settings="settings" :breakpoints="breakpoints">
                  <Slide v-for="item in slides" :key="item.id">
                    <div class="flex flex-col loc-item w-72 xl:w-[370px] mr-8">
                      <Map :map="item.url" />
                      <div class="map-text bg-white p-6 rounded-b-lg flex flex-col justify-between">
                        <h4 class="mb-2">{{ item.title }}</h4>
                        <p class="mb-2"><span>{{item.spanTitle}}</span>{{item.des}}</p>
                        <a target="_blank"
                           :href="item.detailUrl">
                          <button class="underline blue-text">View Details</button>
                        </a>
                      </div>
                    </div>
                  </Slide>


                    <template #addons>
                        <Navigation/>
                    </template>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import Map from '@/components/ui/Map'
import Slider from "../../components/ui/Slider";
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Navigation, Slide, Pagination} from 'vue3-carousel';
import {defineComponent} from 'vue'

export default defineComponent(
    {
        name: "Location",

        components: {
            Map,
            Slider,
            Carousel,
            Slide,
            Navigation,
            Pagination,
        },
        mounted() {
            // Get the current route's path
            const path = this.$route.path;

            // Extract the last segment of the path (assuming it's always the event name)
            const segments = path.split('/');
            this.routeName = segments[segments.length - 1];

        },
        data: () => ({
            settings: {
                itemsToShow: 1.2,
                itemsToScroll: 1,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 3000,
                pauseAutoplayOnHover: true,
            },
            routeName: '',
            breakpoints: {
                640: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                1280: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },

            slides: [
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2783.26689598097!2d-118.4122807350241!3d33.94419391912913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b17164ee2043%3A0xb145b3f570471f26!2sTerminal%20B!5e0!3m2!1sen!2skg!4v1681461690391!5m2!1sen!2skg',
                    title: 'Los Angeles Tom Bradley International Airport - LAX',
                    spanTitle: '',
                    id: 1,
                    des: '1 World Way, Los Angeles, CA 90045 Arrival Floor Level 1 Ticketing Floor Level 3',
                    detailUrl: 'https://goo.gl/maps/6RzZ5DmvTJZAAJ676'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.1857986247096!2d-122.38114408519512!3d37.621317128747584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f778c55555555%3A0xa4f25c571acded3f!2sSan%20Francisco%20International%20Airport!5e0!3m2!1sen!2skg!4v1664871241282!5m2!1sen!2skg',
                    title: 'San Francisco International Airport - SFO',
                    spanTitle: 'TripTel Mobile Services',
                    id: 2,
                    des: "San Francisco, CA 94128 Int'l Terminal Arrivals",
                    detailUrl: 'https://goo.gl/maps/b1ZzdiukmdAfn3Jb8'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.7121427538223!2d-118.32984548530646!3d33.84553343604339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b5346dc4f299%3A0x4756e5222d879365!2s370%20Crenshaw%20Blvd%2C%20Torrance%2C%20CA%2090503%2C%20USA!5e0!3m2!1sen!2skg!4v1664871708670!5m2!1sen!2skg',
                    title: 'Torrance Office',
                    spanTitle: '',
                    id: 4,
                    des: '370 Crenshaw Blvd E207, Torrance, CA 90503',
                    detailUrl: 'https://goo.gl/maps/2QqkHa4X6gB67rYv9'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.6473918942565!2d-118.25838328549278!3d34.052915025143484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c7b25aecbc23%3A0xddf6643043947eb5!2sElephant%20Tours!5e0!3m2!1sen!2skg!4v1660231075719!5m2!1sen!2skg',
                    title: 'Elephant Tour, Downtown LA',
                    spanTitle: '',
                    id: 6,
                    des: 'The Westin Bonaventure Hotel and Suites 404 S Figueroa St, Suite 506, Los Angeles, CA 90071',
                    detailUrl: 'https://www.google.com/maps?ll=34.052911,-118.256195&z=15&t=m&hl=en&gl=KG&mapclient=embed&cid=15994081285107908277'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.5680730796425!2d-118.35635678443629!3d33.849247935846904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b4da74250733%3A0xbda3ec3e1e1ed4c6!2sGulliver%20USA%20Torrance%20%2F%20Los%20Angeles!5e0!3m2!1sen!2skg!4v1680180162724!5m2!1sen!2skg',
                    title: 'Gulliver Torrance',
                    spanTitle: '20125 Hawthorne Blvd, Torrance, CA 90503, United States',
                    id: 7,
                    des: '',
                    detailUrl: 'https://goo.gl/maps/uyVAmdZX9YiefNUE9'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.9038658482064!2d-117.87483358443957!3d33.65965274576291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcde53dd8bcae1%3A0x9e66449949acc8ae!2s2230%20SE%20Bristol%20St%2C%20Newport%20Beach%2C%20CA%2092660%2C%20USA!5e0!3m2!1sen!2skg!4v1680248548508!5m2!1sen!2skg',
                    title: 'Gulliver Irvine',
                    spanTitle: '',
                    id: 8,
                    des: '2230 SE Bristol St, Newport Beach, CA 92660',
                    detailUrl: 'https://goo.gl/maps/cKjvYg1PDYaeBsDcA'
                },
                // {
                //     url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2945.1823652653015!2d-83.42927189999999!3d42.423852999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824adf306bca7b7%3A0x6f3aa7c9fc5e7aea!2s38705%20W%20Seven%20Mile%20Rd%20%23495%2C%20Livonia%2C%20MI%2048152%2C%20USA!5e0!3m2!1sen!2skg!4v1683711044569!5m2!1sen!2skg',
                //     title: 'KDDI Michigan',
                //     spanTitle: '',
                //     id: 8,
                //     des: '38705 Seven Mile Road, Suite 495 Livonia MI 48152',
                //     detailUrl: 'https://goo.gl/maps/PL1SqgcLHMRUBwQV6?coh=178572&entry=tt'
                // },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13249.472522992426!2d-118.31650143267785!3d33.880171707736004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b56685ac2169%3A0xae5fe05abe26b2!2sTokyo%20Central!5e0!3m2!1sen!2skg!4v1688714912891!5m2!1sen!2skg',
                    title: 'Tokyo Central Gardena',
                    spanTitle: '',
                    id: 9,
                    des: '1740 Artesia Blvd, Gardena, CA 90248, United States',
                    detailUrl: 'https://goo.gl/maps/skxSSqcViEhkbe6e6'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.062615807553!2d-117.8840398!3d33.6814428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdf18d57309af%3A0x1558e49a00a800d7!2s665%20Paularino%20Ave%20%23111%2C%20Costa%20Mesa%2C%20CA%2092626%2C%20USA!5e0!3m2!1sen!2skg!4v1693303957155!5m2!1sen!2skg',
                    title: 'Amnet OC',
                    spanTitle: '',
                    id: 9,
                    des: 'Paularino Ave #111, Costa Mesa, CA 92626',
                    detailUrl: 'https://goo.gl/maps/6UyB1iut2yWEJ1dr8'
                },
                {
                    url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3464.381585646854!2d-95.5750999!3d29.7376678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640dcc615a68d07%3A0xd875e5d0afab3233!2sDaido%20Market%20Houston!5e0!3m2!1sen!2skg!4v1698215589060!5m2!1sen!2skg',
                    title: 'Daido Houston',
                    spanTitle: '',
                    id: 10,
                    des: '11146 Westheimer Rd Houston, TX 77042, United States',
                    detailUrl: 'https://maps.app.goo.gl/upLQcr9S9F55caCt6'
                },

            ],
        }),
    }
)
</script>

<style lang="scss">
.location {
    &-bg {
        padding-bottom: 50px;
        background-image: url("../../assets/png/pickup-img.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .map-text {
        width: 100%;
        height: 100%;
    }

    h2 {
        font-weight: 700;
    }

    h4 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
    }

    p {
        font-family: 'Montserrat', sans-serif;
    }

    button {
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
    }

    .loc-slider {
        .loc-item {
            height: 100%;
        }

        //.carousel__viewport {
        //    .carousel__track {
                //width: 13500px;
                //gap: 50px;
                //@media screen and (max-width: 1279px) {
                //  gap: 30px;
                //  width: 7300px;
                //}
                //@media screen and (max-width: 767px) {
                //  gap: 30px;
                //  width: 4500px;
                //}
                //@media screen and (max-width: 639px) {
                //  width: 3376px;
                //}
                //.carousel__slide {
                //    gap: 80px;
                //    text-align: left;
                //    justify-content: flex-start;
                //
                //    @media screen and (min-width: 1024px) {
                //        gap: 15px;
                //    }
                //}
        //    }
        //
        //}

        .carousel__prev,
        .carousel__next {
            width: 42px;
            height: 42px;
            background: #35b8f5;
            border: 1px solid #35b8f5;
            right: 50px;
            top: auto;
            bottom: -90px;
            left: auto;
        }

        .carousel__prev {
            right: 70px;

        }

        .carousel__prev--in-active,
        .carousel__next--in-active {
            background-color: transparent;
            color: #000;
            border: 1px solid #000;
        }

    }
}

</style>
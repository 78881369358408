<template>
    <div class="">
        <div
            class="flex items-center flex-wrap mb-8"
            v-if="!user || !user.id"
        >
            <p
                class="text-2xl text-center md:text-left  font-semibold text-left text-sky-300 mr-2"
            >
                Already have an account?
            </p>
            <p
                class="cursor-pointer text-2xl text-center md:text-left  font-semibold text-[#ee6c31]"
                @click="routeTo"
            >
                {{ $t('auth.sign_in') }}
            </p>
        </div>

        <div class="flex flex-wrap">
            <div class="w-full flex flex-wrap sm:flex-nowrap">
                <input-text
                    class="sm:mr-2"
                    placeholder="First name"
                    :error-message="errors && errors['0.first_name'] ? 'The first name field is required' : ''"
                    v-model="form.first_name"
                    @input="inputForm"

                />

                <input-text
                    placeholder="Last name"
                    :error-message="errors && errors['0.last_name'] ? 'The last name field is required' : ''"
                    v-model="form.last_name"
                    @input="inputForm"

                />
            </div>

            <div class="w-full flex flex-wrap sm:flex-nowrap">
                <input-text
                    type="email"
                    class="sm:mr-2"
                    placeholder="Email"
                    :error-message="errors && errors['0.email'] ? 'The email field is required' : ''"
                    v-model="form.email"
                    @input="inputForm"

                />

                <input-text
                    type="tel"
                    placeholder="Mobile No."
                    :error-message="errors && errors['0.phone_number'] ? 'The Mobile No. field is required' : ''"
                    v-model="form.phone_number"
                    @input="inputForm"

                />
            </div>
        </div>

        <div class="flex justify-center ml-auto md:justify-end w-1/2">
            <button
                v-if="!loading && !paymentData.showComponent"
                :class="paymentData.privacy ? 'w-full justify-center lg:w-1/2 pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-4 px-12 rounded-lg bg-lime-500' : 'w-full justify-center lg:w-1/2 pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-4 px-12 rounded-lg bg-gray-400 pointer-events-none'"
                @click="openCardForms"
            >
                Continue
            </button>

            <button
                v-if="loading && !paymentData.showComponent"
                class="w-full justify-center lg:w-1/2 pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-4 px-12 rounded-lg bg-lime-500"
                disabled
            >
                Loading...
            </button>
        </div>
    </div>
</template>

<script>
import ProductInfo from "@/views/home-pages/book-pages/ProductInfo";
import TextInput from "@/components/ui/TextInput";
import EmailInput from "@/components/ui/EmailInput";
import NumberInput from "@/components/ui/PhoneInput";
import InputText from "@/components/ui/inputs/InputText";
import InputSelect from "@/components/ui/inputs/InputSelect";
import {mapGetters} from "vuex";
import _ from "lodash";
import {loadStripe} from "@stripe/stripe-js";
import moment from "moment";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import PurchaseCheckoutResult from "@/views/purchase/checkout/PurchaseCheckoutResult";

export default {
    name: "PurchaseCheckoutForm",

    components: {InputSelect, InputText, NumberInput, EmailInput, TextInput, ProductInfo},

    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },

    computed: {
        ...mapGetters({
            user: 'getUser',
            paymentData: 'getPaymentData',
            orderData: 'getOrderData',
            orderTotalAmount: 'getOrderTotalAmount',
            allOrderData: 'getAllOrderData',
            deductedAmount: 'getDeductedAmount'
        }),
    },

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
            },
            errors: {
                phone_number: [''],
                email: [''],
                first_name: [''],
                last_name: [''],
            },
            loading: false,

        }
    },

    created() {
        this.setUserFormData();
    },

    methods: {
        setUserFormData() {
            let allOrderData = _.cloneDeep(this.allOrderData);

            if (this.user && this.user.id) {
                this.form.first_name = this.user.first_name;
                this.form.last_name = this.user.last_name;
                this.form.email = this.user.email;
                this.form.phone_number = this.user.phone_number;
            }

            allOrderData.forEach((item, i) => {
                this.$store.dispatch('setDestinationIndex', i)

                const data = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    email: this.form.email,
                    phone_number: this.form.phone_number,
                };
                this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
            });

        },

        inputForm: _.debounce(function (e) {
            let orderData = _.cloneDeep(this.orderData);
            let allOrderData = _.cloneDeep(this.allOrderData);

            orderData.first_name = this.form.first_name;
            orderData.last_name = this.form.last_name;
            orderData.email = this.form.email;
            orderData.phone_number = this.form.phone_number;

            allOrderData.forEach((item, i) => {
                this.$store.dispatch('setDestinationIndex', i)

                const data = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    email: this.form.email,
                    phone_number: this.form.phone_number,
                };
                this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
            });

            this.$store.dispatch('setSameOrderData', orderData);
        }, 300),

        routeTo() {
            this.$store.dispatch('setAuthResultType', 'purchase-checkout');
            this.$router.push({name: 'Login'});
        },

        openCardForms() {
            this.loading = true;

            let form = this.getFormOrderData(this.allOrderData);
            const hasOrderWithPositiveTotal = this.allOrderData.some(order => order.total_amount > 0);
            if(!hasOrderWithPositiveTotal) {
                const updatedForm = form.map(item => {
                    return {
                        ...item,
                        deducted_amount_balance: this.deductedAmount
                    };
                });
                this.$axios.post(`/orders/create-order`, updatedForm)
                    .then(res => {
                        this.allOrderData.forEach((order, i) => {
                            this.$store.dispatch('setDestinationIndex', i)

                            let paymentData = _.cloneDeep(this.paymentData);
                            const data = {
                                order_id: res.data[i].order_id,
                                customer_id: res.data[i].customer_id,
                            };
                            paymentData.showComponent = false;
                            this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                            this.$store.dispatch('setPaymentData', paymentData);
                            this.$store.dispatch('setGiftCardDiscount', 0)
                            this.$router.push({name: 'PurchaseCheckoutResult'});
                            if (i === this.allOrderData.length - 1) {
                                this.loading = false;
                            }
                        })
                    })
                    .catch(e => {
                        this.allOrderData.forEach((order, i) => {
                            if (e.status === 422) {
                                this.errors = e.data.errors;
                            } else {
                                console.log(e);
                            }
                            if (i === this.allOrderData.length - 1) {
                                this.loading = false;
                            }
                        })
                    });
            } else {
                this.$axios.post(`/orders/create-order`, form)
                    .then(res => {
                        this.allOrderData.forEach((order, i) => {
                            this.$store.dispatch('setDestinationIndex', i)

                            let paymentData = _.cloneDeep(this.paymentData);
                            const data = {
                                order_id: res.data[i].order_id,
                                customer_id: res.data[i].customer_id,
                            };
                            paymentData.showComponent = true;
                            this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                            this.$store.dispatch('setPaymentData', paymentData);

                            if (i === this.allOrderData.length - 1) {
                                this.loading = false;
                            }
                        })
                    })
                    .catch(e => {
                        this.allOrderData.forEach((order, i) => {
                            if (e.status === 422) {
                                this.errors = e.data.errors;
                            } else {
                                console.log(e);
                            }
                            if (i === this.allOrderData.length - 1) {
                                this.loading = false;
                            }
                        })
                    });
            }
        },

        getFormOrderData(order) {
            let form = [];

            order.forEach((item) => {
                let formData = {};

                for (let key in item) {
                    switch (key) {
                        case 'from_date':
                        case 'to_date':
                            formData[key] = moment(item[key]).format('MM/DD/YYYY');
                            break;
                        case 'pickup_dt_tm':
                            if (item[key]) {
                                formData[key] = moment(item[key]).format('MM/DD/YYYY');
                            } else {
                                formData[key] = item[key];
                            }
                            break;
                        case 'pickup_time':
                            if (item[key]) {
                                formData[key] = moment(item[key]).format('HH:mm');
                            } else {
                                formData[key] = item[key];
                            }
                            break;
                        default:
                            formData[key] = item[key];
                    }
                }

                form.push(formData);
            });

            return form;
        }

    }
}
</script>

<style lang="scss">

</style>
import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
import Home from "../views/Home";
import Works from "../views/Works";
import Plans from "../views/Plans";
import Support from "../views/Support";

import AuthRoutes from "../views/auth/routes"
import PurchaseRoutes from "../views/purchase/routes"
import Terms from "../views/Terms";
import Privacy from "../views/Privacy";
import Blog from "@/views/Blog";
import BlogDetail from "@/views/BlogDetail";
import Profile from "@/views/Profile";
import Japan from "@/views/Japan";
import Contact from "@/views/Contact";
import ContactResultPage from "@/views/ContactResultPage";
import CoverageMap from "@/views/products-prices/CoverageMap";
import ProductsIndex from "@/views/products-prices/Index";
import PickupsAndReturnsIndex from "@/views/pickups-returns/Index";
import HowToUseIndex from "@/views/how-to-use/Index";
import DeviceInformation from "@/views/products-prices/DeviceInformation";
import CustomerSupport from "@/views/support/CustomerSupport";
import HowTo from "@/views/pickups-returns/HowTo";
import Airport from "@/views/pickups-returns/Airport";
import MoreLocations from "@/views/pickups-returns/MoreLocations";
import Tutorial from "@/views/how-to-use/Tutorial";
import Travel from "@/views/how-to-use/Travel";
import Business from "@/views/how-to-use/Business";
import More from "@/views/how-to-use/More";
import Corporate from "@/views/support/Corporate";
import Prices from "@/views/products-prices/Prices";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'Mobile Hotspot for Travel, Unlimited Data in 100+ Countries | Skygo WiFi' },
    },
    {
        path: '/plans',
        name: 'Plans',
        component: Plans,
    },
    {
        path: '/works',
        name: 'Works',
        component: Works,
        meta: { title: 'How to Receive Your Device | Skygo WiFi' },

    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            title: 'Frequently Asked Questions | Skygo WiFi ',
        }
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: { title: 'Terms & Conditions | Skygo WiFi' },

    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
        meta: { title: 'Privacy Policy | Skygo WiFi' },

    },
    // {
    //     path: '/blog',
    //     name: 'Blog',
    //     component: Blog,
    //     meta: { title: 'Blog | Skygo WiFi' },
    // },
    // {
    //     path: '/blog/:id',
    //     name: 'BlogDetail',
    //     component: BlogDetail,
    //     meta: { title: 'BlogDetail | Skygo WiFi' },
    // },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { title: 'Profile | Skygo WiFi' },
    },
    {
        path: '/japan2023',
        name: 'Japan',
        component: Japan,
        meta: { title: 'Japan | Skygo WiFi' },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: { title: 'Contacts | Skygo WiFi' },
    },
    {
        path: '/contacts-result',
        name: 'ContactResultPage',
        component: ContactResultPage,
        meta: { title: 'Contacts | Skygo WiFi' },
    },
    {
        path: '/products-prices',
        name: 'ProductsPricesIndex',
        component: ProductsIndex,
        redirect: '/products-prices/map',
        meta: { title: 'Products and Prices | Skygo WiFi' },
        children: [
            {
                path: 'map',
                component: CoverageMap,
                meta: { title: 'Coverage Map | Skygo WiFi' },
            },
            {
                path: 'device',
                component: DeviceInformation,
                meta: { title: 'Device Information | Skygo WiFi' },
            },
            {
                path: 'prices',
                component: Prices,
                meta: { title: 'Prices | Skygo WiFi' },
            },
        ],
    },
    {
        path: '/customersupport',
        name: 'CustomerSupport',
        component: CustomerSupport,
        meta: { title: 'Customer Support | Skygo WiFi' },
    },
    {
        path: '/corporate',
        name: 'Corporate',
        component: Corporate,
        meta: { title: 'Corporate | Skygo WiFi' },
    },
    {
        path: '/pickups-returns',
        name: 'PickupsReturnsIndex',
        component: PickupsAndReturnsIndex,
        redirect: '/pickups-returns/how-to',
        meta: { title: 'Pickups and Returns | Skygo WiFi' },
        children: [
            {
                path: 'how-to',
                component: HowTo,
                meta: { title: 'How to Pickup/Return | Skygo WiFi' },
            },
            {
                path: 'airport',
                component: Airport,
                meta: { title: 'Airport | Skygo WiFi' },
            },
            {
                path: 'more-locations',
                component: MoreLocations,
                meta: { title: 'More Locations | Skygo WiFi' },
            },
        ],
    },
    {
        path: '/how-to-use',
        name: 'HowToUseIndex',
        component: HowToUseIndex,
        redirect: '/how-to-use/tutorial',
        meta: { title: 'How to Use | Skygo WiFi' },
        children: [
            {
                path: 'tutorial',
                component: Tutorial,
                meta: { title: 'Tutorial | Skygo WiFi' },
            },
            {
                path: 'travel',
                component: Travel,
                meta: { title: 'Travel | Skygo WiFi' },
            },
            {
                path: 'business',
                component: Business,
                meta: { title: 'Business | Skygo WiFi' },
            },
            {
                path: 'more',
                component: More,
                meta: { title: 'More Use Cases | Skygo WiFi' },
            },
        ],
    },
    ...AuthRoutes,
    ...PurchaseRoutes,
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach((to) => {
//     document.title = to.meta.title
// });
// ...

router.beforeEach((to, from, next) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        .forEach(tag => document.head.appendChild(tag));

    next();
});

// ...
export default router

<template>
  <app-layout>
      <div class="container mx-auto mt-16">
          <div>
              <h1 class="text-3xl text-center font-semibold text-gray-700 mb-4">Rental Agreement (Terms and Conditions)</h1>
              <p class="text-lg text-gray-500">
                  This rental agreement (“Rental Agreement”) defines the terms and conditions of rental service of Skygo WiFi and its accessories including, but not limited to, mobile battery, AC Adapter and USB cable (“Rental Devices”) provided by Skygo Mobile Inc (“COMPANY”) with a brand, “Skygo WiFi” between COMPANY and the customer (“USER”) who submits the rental order (“Rental Order”) to COMPANY. Rental Agreement shall be effective on the date (“Effective Date”) when USER submits a rental order and makes payment of the rental fee.
              </p>
              <div class="ml-2 text-gray-700">
                  <p>1. Acceptance of the terms and conditions</p>
                  <p class="ml-4">By checking the “Agree with Rental Terms and Conditions” checkbox on the application form of COMPANY’s website and submitting a Rental Order, USER acknowledges having thoroughly read and understood the terms and conditions of the present Rental Agreement and fully agreeing with its wording.</p>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>2. Term</p>
                  <p class="ml-4">This Rental Agreement shall commence on the Effective Date and shall end on the date when COMPANY receives Rental Devices in good condition.</p>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>3. Rental Period</p>
                  <div class="ml-4">
                      <p>a. Rental Start Date</p>
                      <p class="ml-4">The rental start date (“Rental Start Date”) shall commence from the date that USER specified in Rental Order. Usage start date shall be the first day of rental fee calculation.</p>
                  </div>
                  <div class="ml-4">
                      <p>b. Rental End Date</p>
                      <p class="ml-4">The rental end date (“Rental End Date”) shall be the last date of the rental period that USER specified in Rental Order.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>4. Cancellation</p>
                  <p class="ml-4">Cancellation of Rental Order must be made five business days before the Rental Start Date. In such cases, no cancellation fees are charged. However, any cancellation after the designated date (five business days before the Rental Start Date) will be charged, $20.</p>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>5. Ownership </p>
                  <p class="ml-4">Ownership and title to Rental Devices shall be retained at all times by COMPANY. USER shall immediately give COMPANY the notice if Rental Devices are lost or damaged. As a result of such event, USER shall compensate and hold harmless COMPANY against all losses and damages.</p>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>6. Use of Rental Devices </p>
                  <div class="ml-4">
                      <p>a. Right to Use</p>
                      <p class="ml-4">USER may retain the right to use the Rental Devices as an end user only in the country selected upon application. Sale or rental of Rental Devices to a third party is strictly prohibited. Rental Devices shall not be operated or used in violation of the law or for any unlawful act.</p>
                  </div>
                  <div class="ml-4">
                      <p>b. Good Care</p>
                      <p class="ml-4">USER shall be solely responsible for the good care and proper use of Rental Devices. USER shall not use Rental Devices in a manner that is inconsistent with the manufacturer’s specification and/or user’s guide and troubleshooting guides provided by COMPANY.</p>
                  </div>
                  <div class="ml-4">
                      <p>c. Fair Use Policy of Portable WiFi</p>
                      <p class="ml-4">There is a fair use policy to make sure that all mobile network users in any country fairly receive a benefit from mobile network as public infrastructure. Extreme usage of Pocket WiFi may result in limiting data transfer speed of your portable WiFi by mobile network carrier based on the fair use policy.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>7. Fees </p>
                  <div class="ml-4">
                      <p>a. Rental fees</p>
                      <p class="ml-4">Rental fees shall be defined in the application page on COMPANY’s web site.</p>
                  </div>
                  <div class="ml-4">
                      <p>b. Insurance fee shall be defined in the application page on COMPANY’s web site.</p>
                  </div>
                  <div class="ml-4">
                      <p>c. Shipping Fee</p>
                      <p class="ml-4">Shipping fee shall be defined in the application page on COMPANY’s web site.</p>
                  </div>
                  <div class="ml-4">
                      <p>d. Late Return Fee</p>
                      <p class="ml-4">$10 plus the regular rental fee per day as late return fee shall be charged to USER.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>8. Charges </p>
                  <p class="ml-4">USER shall pay all fees required under this Rental Agreement. By submitting a Rental Order to COMPANY, it shall be deemed that USER has granted authorization to COMPANY to charge an amount equal to all fees under the Rental Agreement to USER’s credit card. Moreover, USER shall pay other fees covered under the Rental Contract, to the fullest extent permitted by existing laws, including but not limited to:</p>
                  <div class="ml-4">
                      <p class="ml-4">a. applicable taxes, as required;</p>
                      <p class="ml-4">b. repair, damage or loss of Rental Devices, reduction or loss of usability of Rental Devices induced by damage or repair to them, and costs to implement such fees, including those covering claim and legal expenses.</p>
                      <p class="ml-4">c. re-delivery fees in the event that replacement for Rental Devices are requested by USER after USER damaged, lost, or had stolen Rental Devices.</p>
                      <p class="ml-4">d. any other expenses, including legal expenses that resulted from the breach of this Rental Agreement.</p>
                      <p class="ml-4">e. COMPANY shall reserve the right without any exception to charge fees to USER’s credit card for loss, damage or theft as compensation. All payments agreed upon under the Rental Agreement are to be paid by USER.</p>
                      <p class="ml-4">f. If USER does not submit the payment by the specified date, USER shall be liable to pay 15% of the total cost as compensation on top of the original payment, in addition to: all fines, legal costs and other fees regarding Rental Devices assessed against COMPANY or the Rental Devices for the duration of the Rental Agreement, except for those when COMPANY is at fault.</p>
                      <p class="ml-4">g. all costs COMPANY incurs when USER fails to return the Rental Devices in finding and recovering Rental Devices</p>
                      <p class="ml-4">h. all costs, including legal expenses, deriving from collecting unpaid fees, in case USER fails to respond to requests demanding payment.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>9. Delivery </p>
                  <div class="ml-4">
                      <p class="ml-4">a. COMPANY will deliver Rental Devices to the USER by either shipping to a designated address within the U.S., or handing the devices at a contracted counter at pickup locations offered on COMPANY’s website.</p>
                      <p class="ml-4">b. If shipping is selected, COMPANY will send out the Rental Device at least 2 days before the usage start date, unless otherwise specified by USER.</p>
                      <p class="ml-4">c. If pickup is selected, USER can pick up as early as 5 days prior to the start of the use date.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>10. Return </p>
                  <div class="ml-4">
                      <p class="ml-4">a. Within 5 days after the usage period is over, USER shall return all Rental Devices to COMPANY as described in COMPANY’s web site in the same condition as USER received them.</p>
                      <p class="ml-4">b. If shipping back to COMPANY, USER shall put all Rental Devices in the rental pouch, enclose the pouch into the prepaid return padded pack, seal it, and drop off at any Fedex Dropbox or Fedex office. In case COMPANY requests the return of Rental Devices, USER shall return the Rental Devices immediately.</p>
                      <p class="ml-4">c. If Rental Devices are not returned by the required date, USER shall be charged late return fee as a penalty for late return and COMPANY may take any required action to repossess the Rental Devices.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>11. Loss, Damage or Theft</p>
                  <p class="ml-4">11.1 In the event that Rental Devices are lost or stolen, file an incident report at the nearest police station and afterward, submit the resulting documentation to COMPANY. Otherwise, Insurance coverage may not be applicable.</p>
                  <p class="ml-4">11.2 In the event of damage, contact and return the Rental Devices to COMPANY immediately by using the provided prepaid return envelope. Otherwise, Insurance coverage may not be applicable</p>
                  <p class="ml-4">11.3 Lost and Damage Fees</p>
                  <p class="ml-4">In the event that Rental Devices are lost, stolen or damaged, USER shall pay Lost and Damage Fees, listed in the table below, to COMPANY. In case the Insurance coverage is applicable, USER shall pay Lost and Damage Fees according to COMPANY’s web site.</p>
                  <table class="border border-collapse mt-2 ml-4">
                      <thead>
                      <tr>
                          <th class="py-2 px-4 border">Lost/damaged item</th>
                          <th class="py-2 px-4 border">Fee without insurance</th>
                          <th class="py-2 px-4 border">Fee with insurance</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td class="py-2 px-4 border">Portable WiFi</td>
                          <td class="py-2 px-4 border">$300</td>
                          <td class="py-2 px-4 border">$50</td>
                      </tr>
                      <tr>
                          <td class="py-2 px-4 border">USB Wall Charger</td>
                          <td class="py-2 px-4 border">$10</td>
                          <td class="py-2 px-4 border">$0</td>
                      </tr>
                      <tr>
                          <td class="py-2 px-4 border">Charger cable (USB)</td>
                          <td class="py-2 px-4 border">$10</td>
                          <td class="py-2 px-4 border">$0</td>
                      </tr>
                      <tr>
                          <td class="py-2 px-4 border">Pouch</td>
                          <td class="py-2 px-4 border">$10</td>
                          <td class="py-2 px-4 border">$0</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>12. Termination</p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">COMPANY shall retain the right to immediately terminate the present Rental Contract without prior notification and request the immediate retrieval of Rental Devices if USER:</p>
                      <p class="ml-4">a) breaches the contract in any form;</p>
                      <p class="ml-4">b) knowingly submits false or incorrect information.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>13. Warranty </p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">All Rental Devices are guaranteed to be delivered to USER in good working conditions. If the Rental Device malfunctions, USER shall notify COMPANY immediately. COMPANY will provide customer support to offer the best alternative solution. Even in the unlikely event of service failure by COMPANY fault, COMPANY can only take responsibility for the period of time after the USER makes notification.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>14. Disclaimer  </p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">COMPANY assumes no responsibility for the unusable situation resulted by the communication COMPANY who owns the line (including the packet usage limit set by the communication COMPANY per Fair Use Policy).</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>15. Force Majeure  </p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">COMPANY shall not be held responsible for delay or suspension of service caused by occurrences beyond COMPANY’s reasonable control, including, but not limited to natural disasters, war, terrorism acts, coup d’etat, riots, worker strikes, suspension of production, volcano eruptions, or labor, resources, or energy crisis</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>16. Indemnification   </p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">USER agrees to indemnify, defend, and hold harmless COMPANY, our officers, employees, independent contractors and other customers against any and all claims, demands, losses, costs, or expense, including but not limited to reasonable attorney’s fees, in any way connected with
                          (a) a breach of this Rental Agreement; and
                          (b) any dispute between USER and any third party service, which you engaged through our information.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>17. No Waiver</p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">Failure of COMPANY to enforce any rights under the present Rental Agreement shall not be considered as waiver of consequent breaches. Any waiver of any breach of any provision of the present Rental Agreement granted by COMPANY shall not be considered as waiver of any succeeding breach of such provision. Acceptance of payment by COMPANY with awareness of a default by USER shall not be considered a waiver of any breach.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>18. Governing Law</p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">This Agreement shall be governed by and construed in accordance with the laws of the State of California. Any action to enforce this Agreement must be brought within the state whose laws govern this Agreement.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>19. Customer Service</p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">If you have any questions or concerns regarding these terms and conditions or your use of our products or services, please contact our customer support team by email: info@skygowifi.com. The Skygo team will be happy to assist you with any issues you may encounter and answer any questions you may have. We strive to provide timely and effective customer support to ensure a positive user experience.</p>
                  </div>
              </div>
              <div class="ml-2 text-gray-700">
                  <p>20. Miscellaneous </p>
                  <div class="ml-2 text-gray-700">
                      <p class="ml-4">COMPANY may change the terms and conditions without the consent of the USER without prior notice. The most current Rental Agreement is always the agreement on COMPANY’s web site.</p>
                  </div>
              </div>
          </div>
      </div>
  </app-layout>
</template>

<script>
import AppLayout from "../layouts/AppLayout";
export default {
  name: "Faq",
  components: {AppLayout}
}
</script>

<style lang="scss">
.terms {
  h1 {
    color: #444444;
    margin-bottom: 1rem;

  }
  h2 {
    color: #444444;;
    margin-bottom: 1rem;

  }
  strong {
    color: #444444;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 18px;
  }
  p {
    color: #444444;
    margin-bottom: 1rem;
    font-size: 18px;
  }
}
</style>
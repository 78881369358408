import Login from "@/views/auth/Login";
import Register from "@/views/auth/Register";
import AuthResult from "@/views/auth/Result";
import Reset from "./Reset";

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: 'User Login | Skygo WiFi' },

    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: { title: 'Register for WiFi Rental | Skygo WiFi' },

    },
    {
        path: '/auth-result',
        name: 'AuthResult',
        component: AuthResult,

    },
    {
        path: '/reset',
        name: 'Reset',
        component: Reset,
        meta: { title: 'Reset Password | Skygo WiFi' },

    },
];
<template>
<div class="mt-16">
  <div class="container mx-auto px-4">
    <div class="contacts flex sm:justify-around pl-6 sm:pl-0 sm:items-center flex-col sm:flex-row gap-6 sm:gap-0">
      <router-link to="/" class="">
        <img class="w-44" src="@/assets/png/SkyLogo.png" alt="">
      </router-link>
      <ul class="flex gap-6 flex-col text-left pl-6 sm:pl-0">
        <li class="list-disc"><router-link to="/privacy">{{ $t('privacy') }}</router-link></li>
        <li class="list-disc"><router-link to="/terms">{{ $t('terms') }}</router-link></li>
        <li class="list-disc"><router-link to="/support">{{ $t('faq') }}</router-link></li>
        <li class="list-disc"><router-link to="/gift-card">Gift Card</router-link></li>
        <li class="list-disc"><router-link to="/contact">Become a Skygo</router-link></li>
      </ul>
      <ul class="list-container flex flex-col gap-6 justify-center">
        <li class="flex items-center gap-4">
          <img src="@/assets/svg/location-icon.svg" alt="">
          <a>370 Crenshaw Blvd E207, Torrance, CA 90503</a>
        </li>
        <li class="flex items-center gap-4">
          <img src="@/assets/svg/mail-icon.svg" alt="">
          <a>info@skygowifi.com</a>
        </li>
        <li class="flex items-center gap-4">
          <img src="@/assets/svg/call2-icon.svg" alt="">
          <a>+1 833 467 5946</a>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "Contacts",
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    }
}
</script>

<style lang="scss">
.contacts {
  a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
  .list-container {
    max-width: 322px;
    width: 100%;
  }
}

</style>
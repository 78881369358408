<template>
    <div class="bg-white">
        <app-layout>

            <div>
                <div class="wrapper container mx-auto">
                    <div class="register-container mx-auto border rounded-2xl p-8 mt-32">
                        <h2 class="text-3xl mb-6">Register</h2>

                        <TextInput
                            placeholder="First name"
                            :error-message="errors && errors.first_name ? errors.first_name[0] : ''"
                            v-model="form.first_name"
                        />

                        <TextInput
                            placeholder="Last name"
                            :error-message="errors && errors.last_name ? errors.last_name[0] : ''"
                            v-model="form.last_name"
                        />

                        <EmailInput
                            placeholder="Email address"
                            :error-message="errors && errors.email ? errors.email[0] : ''"
                            v-model="form.email"
                        />

                        <PhoneInput
                            :error-message="errors && errors.phone_number ? errors.phone_number[0] : ''"
                            v-model="form.phone_number"
                        />

                        <PasswordInput
                            :error-message="errors && errors.password ? errors.password[0] : ''"
                            v-model="form.password"
                            @keyup.enter="submit"
                        />

                        <div class="flex justify-between items-center flex-wrap gap-2 sm:gap-0">
                            <Button
                                text="Register now"
                                :loading="loading"
                                @click="submit"
                            />
                            <div class="flex gap-1 flex-wrap items-center">
                                <p>Have an account?</p>
                                <router-link to="/login">Login now!</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </app-layout>
    </div>
</template>

<script>
import NavLogin from "../../components/NavLogin";
import Footer from "../../components/Footer";
import Contacts from "../home-pages/Contacts";
import TextInput from "../../components/ui/TextInput";
import EmailInput from "../../components/ui/EmailInput";
import PasswordInput from "../../components/ui/PasswordInput";
import PhoneInput from "../../components/ui/PhoneInput";
import Button from "../../components/ui/Button";
import AppLayout from "../../layouts/AppLayout";

export default {
    name: "Register",

    components: {AppLayout, PhoneInput, PasswordInput, EmailInput, TextInput, Contacts, Footer, NavLogin, Button},

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                phone_number: '',
                email: '',
                password: '',
            },
            errors: null,
            loading: false,
        }
    },

    methods: {
        submit() {
            this.loading = true;

            let url = '/register';
            let affiliateCode = this.$route.query.code;

            if (affiliateCode) {
                url += `?code=${affiliateCode}`
            }

            this.$axios.post(url, this.form)
                .then(() => {
                    this.loading = false;

                    this.$store.dispatch('setAuthResultType', 'register');
                    this.$router.push({name: 'AuthResult'});
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                        this.$toast.warning(e.data.message, {
                            position: "bottom-left",
                        });
                    }

                    this.loading = false;
                });
        }
    }
}
</script>

<style lang="scss">
.wrapper {
    @media screen and (max-width: 576px) {
        padding: 0 1.5rem;
    }

    .register-container {
        border-color: #f1792d;
        max-width: 560px;
        width: 100%;

        h2 {
            font-weight: bold;
            color: #f1792d;
            text-align: center;
        }

        button {
            border-radius: 4px;
            white-space: nowrap;
        }

        a {
            font-size: 18px;
            font-weight: bolder;
            color: #f1792d;
        }
    }
}

</style>
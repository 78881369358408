<template>
    <app-layout>
        <div class="wrapper container mx-auto">

            <div class="login-container mx-auto border rounded-2xl p-4 sm:p-8 mt-32">

                <h2 class="text-3xl mb-6">Reset password</h2>

                <input-text
                    placeholder="Email address"
                    type="email"
                    v-model="form.email"
                />

                <input-text
                    type="password"
                    v-model="form.password"

                    placeholder="Current password"
                />
                <input-text
                    type="password"
                    v-model="form.new_password"

                    placeholder="New password"
                />
                <input-text
                    type="password"
                    v-model="form.new_password_confirmation"
                    @keyup.enter="submit"
                    placeholder="Confirm new password"
                />

                <div class="flex justify-between items-center flex-wrap gap-2 sm:gap-0">
                    <Button
                        text="Reset password"
                        :loading="loading"
                        @click="submit"
                    />
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import InputText from "../../components/ui/inputs/InputText";
import AppLayout from "../../layouts/AppLayout";
import Button from "../../components/ui/Button";

export default {
    name: "Reset",

    components: {Button, AppLayout, InputText},

    data() {
        return {
            form: {
                email: '',
                password: '',
                new_password: '',
                new_password_confirmation: ''
            },
            errors: null,
            loading: false,
        }
    },

    methods: {
        submit() {
            this.loading = true;

            this.$axios.post(`/change-password`, this.form)
                .then(res => {
                    this.$store.dispatch('setAuthResultType', 'reset-password');
                    this.$router.push({name: 'AuthResult'});
                    this.loading = false;
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>